import React, { useState, useEffect } from "react";
import { useLocation, Link, useHistory } from 'react-router-dom';
import Dialog from "@material-ui/core/Dialog";
import { useDispatch, useSelector } from "react-redux";
import {
  createSubscriptionOrder,
  verifySubscriptionPlan,
} from "../../store/business/subscriptionPlan/subscriptionPlanApi";
import "./dialog.css";
import { makeStyles } from "@material-ui/core/styles";
import DialogContent from "@material-ui/core/DialogContent";
import TextField from "@material-ui/core/TextField";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import { Select, Input } from "../../_metronic/_partials/controls";
import { sendFailure, sendSuccess } from "../../store/toast/toastSlice";

import ClearIcon from "@material-ui/icons/Clear";
import { createCollectPayment } from "../../store/business/bookings/bookingApi";
import {
  applyCoupone,
  orderCreate,
} from "../../store/business/subscriptionPlan/subscriptionPlanSlice";
import { ProfileUpdateData } from "../../store/business/authSlice";
import { getCountryCodeList } from "../../store/countryCode/countryCodeSlice";

const useStyles = makeStyles((theme) => ({
  textField: {
    border: "2px solid #007669",
    height: "25px",
  },
  textInput: {
    padding: 8,
    fontSize: "14px",
  },
}));

export default function PackageUpdateDialog({ content, svg, bookingDetails }) {
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [paymentDetails, setPaymentDetails] = useState({});
  const [paymentMode, setPaymentMode] = useState("");
  const dispatch = useDispatch();
  const { role, user } = useSelector((state) => state.authPersist);
  const { countryCode } = useSelector((state) => state.countryCode);
  const currencySymbol = countryCode.filter(
    (el) => el.countryCode === user.countryCode
  );
  //console.log(user)
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setPaymentDetails({});
    setOpen(false);
  };

  useEffect(() => {
    dispatch(getCountryCodeList());
  }, []);

  const applyCouponHandler = (code) => {
    console.log(code);
  };
  //console.log(bookingDetails);
  const collectPaymentSchema = Yup.object().shape({
    couponCode: Yup.string(),
  });

  const submitHandler = (values) => {
   // console.log(values);
    dispatch(applyCoupone(values))
      .then((response) => {
        //console.log(response);
        setPaymentDetails(response);
        dispatch(sendSuccess("Coupon apply successfully"));
        //handleClose();
      })
      .catch((err) => {
        dispatch(sendFailure(err.message));
        console.log(err.message);
      });
  };

  const orderCreateHandler = () => {
    dispatch(
      orderCreate({
        subscriptionUuid: bookingDetails.uuid,
        couponCode: paymentDetails.couponCode,
        duration: bookingDetails.duration === "monthly" ? 1 : 12,
      })
    )
      .then((response) => {
        console.log(response);
        // setPaymentDetails(response);
        dispatch(sendSuccess("Subscription update successfully"));
        handleClose();
      })
      .catch((err) => {
        dispatch(sendFailure(err.message));
        console.log(err.message);
      });
  };

  const isEmpty = Object.keys(paymentDetails).length === 0;
  //console.log(isEmpty);

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      console.log("Errror Occured");
      return;
    }

    const result = createSubscriptionOrder({
      subscriptionUuid: bookingDetails.uuid,
      couponCode: paymentDetails.couponCode,
      duration: bookingDetails.duration === "monthly" ? 1 : 12,
    }).then((response) => {
      ///console.log(response);
      handleClose();
      if (response.data.data.currency === 0) {
      }
      const options = {
        key: process.env.key,
        amount: response.data.data.amount,
        currency: response.data.data.currency,
        name: "Bellaz Subscription",
        description: "Test Transaction",
        // image: { img },
        order_id: response.data.data.id,
        callback_url: `${process.env.REACT_APP_STRIPE_REDIRECT_URL}/profile`,
        redirect: true,

        theme: {
          color: "#007669",
        },
        handler: async function(response) {
          // console.log(response.razorpay_payment_id);
          // console.log(response.razorpay_order_id);
          // console.log(response.razorpay_signature);
          const data = {
            orderCreationId: response.id,
            razorpayPaymentId: response.razorpay_payment_id,
            razorpayOrderId: response.razorpay_order_id,
            razorpaySignature: response.razorpay_signature,
          };

          verifySubscriptionPlan(data)
            .then((response) => {
              //console.log(response.data);
              //dispatch(ProfileUpdateData(response.data.data));
              history.replace("/profile");
            })
            .catch((err) => {
              console.log(err.message);
            });
        },
      };

      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    });
  }
  return (
    <div>
      {content ? (
        <span className="navi-text" onClick={handleClickOpen}>
          {content}
        </span>
      ) : (
        <SVG src={svg} onClick={handleClickOpen} />
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"sm"}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <div
          id="alert-dialog-title"
          className="d-flex justify-content-end align-items-center bg-primary text-white mb-5 w-100 pt-4 pb-4"
        >
          <div
            className="d-flex justify-content-between flex-row align-items-center pr-4 pl-5"
            style={{ width: "70%" }}
          >
            <h2>Purchase Package</h2>
            <ClearIcon
              className="display-4"
              onClick={handleClose}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
        <DialogContent>
          <div>
            <div className="d-flex flex-column align-items-center">
              <div
                className="d-flex flex-column w-100 justify-content-center align-items-start mb-4"
                style={{ borderBottom: "1px solid #007669" }}
              >
                <div className="d-flex w-100 flex-row align-items-start justify-content-start mb-5">
                  <h4 className="w-75">
                    <strong>Package Name</strong>
                  </h4>
                  <h4 className="w-25 align-self-end d-flex flex-row justify-content-end">
                    <strong>
                      {bookingDetails.packageName.charAt(0).toUpperCase() +
                        bookingDetails.packageName.slice(1)}
                    </strong>
                  </h4>
                </div>
                <div className="d-flex w-100 flex-row align-items-start justify-content-start mb-5">
                  <h4 className="w-75">
                    {" "}
                    <strong>Duration</strong>
                  </h4>
                  <h4 className="w-25 align-self-end d-flex flex-row justify-content-end">
                    <strong>
                      {bookingDetails.duration.charAt(0).toUpperCase() +
                        bookingDetails.duration.slice(1)}
                    </strong>
                  </h4>
                </div>
                <div className="d-flex w-100 flex-row align-items-start justify-content-start mb-5">
                  <h4 className="w-75">
                    {" "}
                    <strong>Price</strong>
                  </h4>
                  <h4 className="w-25 align-self-end d-flex flex-row justify-content-end">
                    {/* <SVG
                      className="rupee-svg"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Indian-Rupee-symbol.svg"
                      )}
                      style={{margin: '0'}}
                    ></SVG> */}
                    <span style={{ marginRight: "5px" }}>
                      {currencySymbol[0]
                        ? currencySymbol[0].currencySymbol
                        : "₹"}
                    </span>
                    <strong>{bookingDetails.amount}</strong>
                  </h4>
                </div>
              </div>
              {!isEmpty && (
                <div
                  className="d-flex w-100 flex-row align-items-start justify-content-start mb-5"
                  style={{ borderBottom: "1px solid #007669" }}
                >
                  <h4 className="w-75 mb-4">
                    <strong>Discounted Price</strong>
                  </h4>
                  <h4 className="w-25 align-self-start d-flex flex-row justify-content-end">
                    {/* <SVG
                      className="rupee-svg"
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Indian-Rupee-symbol.svg"
                      )}
                      style={{ margin: "0" }}
                    ></SVG> */}
                    <span style={{ marginRight: "5px" }}>
                      {currencySymbol[0]
                        ? currencySymbol[0].currencySymbol
                        : "₹"}
                    </span>
                    <strong>{paymentDetails.discountedPrice}</strong>
                  </h4>
                </div>
              )}
              <Formik
                initialValues={{
                  couponCode: "",
                }}
                validationSchema={collectPaymentSchema}
                onSubmit={(values, errors) => {
                  console.log(values);
                  const newValues = {
                    ...values,
                    subscriptionUuid: bookingDetails.uuid,
                    duration: bookingDetails.duration === "monthly" ? 1 : 12,
                  };
                  console.log(newValues);
                  submitHandler(newValues);
                  // dispatch(applyCouponeCode(newValues))
                  //   .then((response) => {
                  //     console.log(response);
                  //     dispatch(
                  //       sendSuccess("coupon apply successfully")
                  //     );
                  //     //handleClose();
                  //   })
                  //   .catch((err) => {
                  //     dispatch(sendFailure());
                  //     console.log(err.message);
                  //   });
                }}
              >
                {({ values, errors, touched, handleChange }) => {
                  // console.log(values);
                  return (
                    <Form className="w-100">
                      <div className="d-flex justify-content-start flex-column align-items-center">
                        <div className="d-flex w-100 flex-row align-items-center">
                          {/* <h4 style={{ margin: "0" }}>Enter Coupon Code</h4> */}
                          <div className="purchase_div">
                            <TextField
                              name="couponCode"
                              type="text"
                              variant="outlined"
                              value={values.couponCode}
                              onChange={handleChange}
                              className="purchase_text_box"
                              // style={{
                              //   borderRadius: " 10px 0px 0px 10px",
                              //   height: "56px",
                              //   width: "220px",
                              // }}
                            />
                            <button
                              className="btn bg-primary font-weight-bold h6 text-white apply_button"
                              type="submit"
                              // style={{
                              //   borderRadius: " 0px 10px 10px 0px",
                              //   height: "56px",
                              //   marginLeft: "-4px",
                              //   width: "100px",
                              // }}
                            >
                              Apply
                            </button>
                          </div>
                          <div style={{ width: "25%", margin: "0 auto" }}>
                            <button
                              className="btn bg-primary font-weight-bold h6 text-white purchase_button"
                              type="button"
                              onClick={() => {
                                //console.log(paymentDetails);
                                const isEmpty2 = Object.keys(paymentDetails).length === 0;
                                //console.log(isEmpty2)
                                if (paymentDetails.discountedPrice === 0) {
                                 // console.log("Free");
                                  orderCreateHandler();
                                } else if (user.countryCode === 91) {
                                  displayRazorpay();
                                } else if (user.countryCode !== 91 && isEmpty2)  {
                                  history.push({ pathname: `/checkout/${bookingDetails.uuid}/${bookingDetails.duration}` });
                                } else {
                                  history.push({ pathname: `/checkout/${bookingDetails.uuid}/${bookingDetails.duration}/${paymentDetails.couponCode}`})
                                }
                              }}
                              // style={{
                              //   borderRadius: "10px",
                              //   width: "130px",
                              //   height: "56px",
                              // }}
                            >
                              Purchase
                            </button>
                          </div>
                        </div>
                      </div>
                      {/* <pre>{JSON.stringify({ values, errors }, null, 4)}</pre> */}
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </DialogContent>
      </Dialog>
    </div>
  );
}
