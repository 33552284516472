import React from "react";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import LanguageIcon from "@material-ui/icons/Language";
function BellazFooter() {
  return (
    <div className="container-fluid w-100 footer-Main">
      <footer className="footer-area w-100 p-0 d-flex flex-column justify-content-center align-items-center m-0 p-0">
        <div className="footer-big">
          <div className="container w-100">
            <div className="row justify-content-between d-flex flex-row w-100">
              <div className="col-md-3 col-sm-12 p-0">
                <div className="widget-about w-100 mb-5">
                  <img
                    className="footer-bellaz-logo"
                    src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
                    alt="bellaz logo img-fluid"
                  />
                  <ul className="d-flex flex-row justify-content-between  align-items-center w-75">
                    <li>
                      <i class="fab fa-facebook-f "></i>
                    </li>

                    <li>
                      <i class="fab fa-twitter"></i>
                    </li>
                    <li>
                      <i class="fab fa-linkedin "></i>
                    </li>
                    <li>
                      <i class="fab fa-instagram"></i>
                    </li>
                  </ul>
                </div>
              </div>

              <div className="col-md-3 col-sm-4 col-lg-2 p-0">
                <div className="footer-widget">
                  <div className="footer-menu footer-menu--1">
                    <h4 className="footer-widget-title">About bellaz</h4>
                    <ul>
                      <li>
                        <a href="/about">About Us</a>
                      </li>
                      <li>
                        <a href="/faq">FAQ</a>
                      </li>
                      <li>
                        <a href="/cancellation">Cancel and Refund Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* <div className="col-md-3 col-sm-4 col-lg-2 p-0">
                <div className="footer-widget">
                  <div className="footer-menu">
                    <h4 className="footer-widget-title">For business</h4>
                    <ul>
                      <li>
                        <a href="#">For Partners</a>
                      </li>
                      <li>
                        <a href="#">Pricing</a>
                      </li>
                      <li>
                        <a href="#">Support For Partners</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div> */}

              <div className="col-md-3 col-sm-4 col-lg-2 p-0">
                <div className="footer-widget">
                  <div className="footer-menu no-padding">
                    <h4 className="footer-widget-title">Legal</h4>
                    <ul>
                      <li>
                        <a href="/privacy">Privacy Policy</a>
                      </li>
                      <li>
                        <a href="/terms">Terms and Conditions</a>
                      </li>
                      <li>
                        <a href="/legalInfo">Website Legal Information</a>
                      </li>
                      <li>
                        <a href="/cookiePolicy">Cookie Policy</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-md-4 col-sm-4 col-lg-2 p-0">
                <div className="footer-widget p-0">
                  <div className="footer-menu no-padding p-0">
                    <h4 className="footer-widget-title">Free Mobile App</h4>
                    <div className="d-flex w-100 flex-column">
                      <img
                        src={toAbsoluteUrl(
                          "/media/BellazMain/button/apple.png"
                        )}
                        alt=""
                        className="w-100 img-fluid mb-2"
                      />
                      <img
                        src={toAbsoluteUrl(
                          "/media/BellazMain/button/google-play-badge.png"
                        )}
                        alt=""
                        className="w-100 img-fluid bg-transparent"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mini-footer">
          <div className="container">
            <div className="row p-0">
              <div className="col-md-12 p-0">
                <div className="container-fluid d-flex flex-row justify-content-between align-item-center text-center copyright-main p-0">
                  <div className="copyrightt p-0 text-black-50">
                    <LanguageIcon />
                    <span className="h6">English</span>
                  </div>
                  <div className="h6 text-black-50">
                    <p>
                      © 2021
                      <span>Bellaz.com</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default BellazFooter;
