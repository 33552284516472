import { createSlice } from "@reduxjs/toolkit";

import * as requestFromServer from "./countryCodeApi";

const initialState = {
  countryCode: [],
  calenderType: [],
  //countryCodeSliceSymbol : []
};

export const countryCodeSlice = createSlice({
  name: "countryCode",
  initialState,
  reducers: {
    updateCountryCodeList(state, action) {
      state.countryCode = action.payload;
    },
    updateCalenderType(state, action) {
      state.calenderType = action.payload;
    },
    // updateCountryCodeSymbol(state, action) {
    //   console.log('dddddd');
    //   const mySymbol = state.countryCode.filter((el) => el.countryCode === action.payload.countryCode);
    //   state.countryCodeSliceSymbol = mySymbol;
    // }
  },
});

// export const getCountryCodeSymbol = (data) => {
//   console.log(data);
//   return async (dispatch) => {
//     dispatch(countryCodeSlice.actions.updateCountryCodeSymbol(data));
//   };
// };

export const getCountryCodeList = () => {
  return async (dispatch) => {
    return requestFromServer
      .getCountryCodeList()
      .then((response) => {
        //console.log(response.data);
        dispatch(countryCodeSlice.actions.updateCountryCodeList(response.data.data.CountryCodes));
        dispatch(countryCodeSlice.actions.updateCalenderType(response.data.data.CalenderType));
      })
      .catch((err) => {
        alert(err.message);
      });
  };
};
