import React, { useEffect, useState } from "react";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { getCategory } from '../../store/business/authApi';
import SVG from "react-inlinesvg";

function BellazCategory() {
  const [categories, setCategories] = useState([]);
  useEffect(() => {
    getCategory()
      .then((response) => {
        console.log(response.data.data);
        setCategories(response.data.data);
      })
      .catch((err) => {
        console.log(err.message);
      });
  }, []);
  console.log(categories);
  return (
    <div className="container-fluid d-flex flex-column align-items-center text-center justify-content-center p-0 bellaz-cat position-relative">
      <img
        className="img-fluid position-absolute flower-main2 d-none d-lg-flex"
        src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
      />
      <div className="position-relative container business-content justify-content-center align-items-center d-flex flex-column">
        <h1 className="text-black display-4 mb-5">Service Categories</h1>
        <h6 className="text-black-50">
          Search from our wide variety of services and packages. Search from our
          wide valety of services.
        </h6>
      </div>
      <div className="row p-0 m-0 category-row d-flex justify-content-lg-center justify-content-md-center">
        <img
          src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
          alt=""
          className=" col-12 col-lg-2 col-md-2 p-0 led"
        />
        <div className="col-12 col-lg-8 col-md-8 align-self-center category-content">
          <div className="row mb-lg-5 mb-sm-2">
            {categories.map((el) => {
              return (<div className="col-lg-3 col-md-3 col-sm-3 d-block col-6 mb-5">
                <div className="card category-cards d-flex align-items-center justify-content-center p-5">
                  <img
                    src={`${process.env.REACT_APP_MEDIA_URL}/${el.image}`}
                    alt=""
                    width="90%"
                    height="100px"
                    className="rounded"
                  />
                  <span className="h4 mt-4" style={{height: '40px'}}>{el.name}</span>
                </div>
              </div>)
            })}
            {/* <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Salon for Women</span>
            </div>
          </div> */}
            {/* <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl("/media/BellazMain/svg icons/spa.svg")}
              ></SVG>
              <span className="h4">Spa</span>
            </div>
          </div> */}
            {/* <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Hair</span>
            </div>
          </div> */}
          </div>
        </div>
        <img
          src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
          alt=""
          className="cat-leaf-right align-self-end col-md-2 col-lg-2 p-0 m-0 led2"
        />
      </div>
      {/* <div className="row p-0 m-0 category-row d-flex justify-content-lg-center justify-content-md-center">
      <img
        src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
        alt=""
        className=" col-12 col-lg-2 col-md-2 p-0 led"
      />
      <div className="col-12 col-lg-8 col-md-8 align-self-center category-content">
        <div className="row mb-lg-5 mb-sm-2">
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Salon for Men</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Salon for Women</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl("/media/BellazMain/svg icons/spa.svg")}
              ></SVG>
              <span className="h4">Spa</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Hair</span>
            </div>
          </div>
        </div>
        <div className="row  mb-lg-5 mb-sm-2">
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Beauty</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/location.svg"
                )}
              ></SVG>
              <span className="h4">Barbers</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/nail-polish.svg"
                )}
              ></SVG>
              <span className="h4">Nails</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 d-block col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl("/media/BellazMain/svg icons/massage.svg")}
              ></SVG>
              <span className="h4">Massage</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/physical-therapy.svg"
                )}
              ></SVG>
              <span className="h4">Therapy</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/facial-mask.svg"
                )}
              ></SVG>
              <span className="h4">Facial</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="card category-cards d-flex align-items-center justify-content-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl("/media/BellazMain/svg icons/wax.svg")}
              ></SVG>
              <span className="h4">Waxing & Threading</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-sm-3 col-6">
            <div className="card category-cards d-flex align-items-center justify-content-lg-center p-5">
              <SVG
                className="card-svg img-fluid"
                src={toAbsoluteUrl(
                  "/media/BellazMain/svg icons/pedicure.svg"
                )}
              ></SVG>
              <span className="h4">Manicure & Pedicure</span>
            </div>
          </div>
        </div>
      </div>
      <img
        src={toAbsoluteUrl("/media/BellazMain/Images/pattern1.png")}
        alt=""
        className="cat-leaf-right align-self-end col-md-2 col-lg-2 p-0 m-0 led2"
      />
    </div> */}
    </div>
  );
}

export default BellazCategory;
