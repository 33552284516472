import React, { Suspense } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { MyPage } from "./pages/MyPage";
import { DashboardPage } from "./pages/DashboardPage";
import { BranchCreate } from "../pages/branch/create";
import { BranchesPage } from "../pages/branch/index";
import { ServiceCreate } from "../pages/services/create";
import { ServicesPage } from "../pages/services/index";
import { PackageCreate } from "../pages/packages/create";
import { PackagesPage } from "../pages/packages/index";
import { BookingsPage } from "../pages/bookings/index";
import { TablePresent } from "../pages/bookings/tablePresent";
import { TableTax } from "../pages/bookings/tableTax";
import { BookingCreate } from "../pages/bookings/create";
import { ChairsPage } from "../pages/chairs/index";
import { ChairCreate } from "../pages/chairs/create";
import { StaffPage } from "../pages/staff/index";
import { ExpenseCategoryPage } from "../pages/expense/category/index";
import { ExpenseCategoryCreate } from "../pages/expense/category/create";
import { ExpenseCategoryView } from "../pages/expense/category/view";
import { ExpenseTypePage } from "../pages/expense/type/index";
import { ExpenseCategoryTypeCreate } from "../pages/expense/type/create";
import { ExpenseTypeView } from "../pages/expense/type/view";
import { ExpenseCashDrawerPage } from "../pages/expense/cashDrawer/index";
import { ExpenseRaPage } from "../pages/expense/ra/index";
import { ExpensePoPage } from "../pages/expense/po/index";
import { ExpenseRaCreate } from "../pages/expense/ra/create";
import { ExpenseRaView } from "../pages/expense/ra/view";
import { ExpensePoCreate } from "../pages/expense/po/create";
import { ExpensePoView } from "../pages/expense/po/view";
import { StaffCreate } from "../pages/staff/create";
import { StaffHolidayPage } from "../pages/staff/holidays/index";
import { StaffHolidayCreate } from "../pages/staff/holidays/create";
import { ShiftsPage } from "../pages/shifts/index";
import { HolidayPage } from "../pages/holiday/index";
import { HolidayCreate } from "../pages/holiday/create";
import { ShiftCreate } from "../pages/shifts/create";
import { SchedulePage } from "../pages/schedule/index";
import { ScheduleCreate } from "../pages/schedule/create";
import { ProfilePage } from "../pages/profile/index";
import { SegmentsPage } from "../pages/segment/index";
import { SegmentCreate } from "../pages/segment/create";
import { ServiceCategoriesPage } from "../pages/serviceCategories";
import { ServiceCategoryCreate } from "../pages/serviceCategories/create";
import { SegmentView } from "../pages/segment/view";
import { ServiceCategoryView } from "../pages/serviceCategories/view";
import { BranchView } from "../pages/branch/view";
import { AddImage } from "../pages/branch/addImage";
import { ServiceView } from "../pages/services/view";
import { ChairView } from "../pages/chairs/view";
import { ShiftView } from "../pages/shifts/view";
import { StaffView } from "../pages/staff/view";
import { SubscriptionsPage } from "../pages/subscription";
import { SubscriptionCreate } from "../pages/subscription/create";
import { TransactionPage } from "../pages/transaction";
import { PackageView } from "../pages/packages/view";
import { ScheduleView } from "../pages/schedule/view";
import { CustomerPage } from "../pages/customers";
import { Upgrade } from "../components/upgrade/ugrade";
import { LoyaltyPointsBusinessPages } from "../pages/loyaltyPoints/index";
import Faq from "../components/footerComponents/Faq";
import Privacy from "../components/footerComponents/Privacy";
import Contact from "../components/footerComponents/Contact";
import { PaymentPage } from "../pages/paymentSummary";
import { BookingUpdate } from "../pages/bookings/view";
import { BookingView } from "../pages/bookings/bookingView";
import CheckoutElement  from "../components/StripePaymentComponent/checkOutElement";
import BellazMain from "../components/bellazNew/BellazMain";
import { Demo2Dashboard } from "../_metronic/_partials/dashboards/Demo2Dashboard";
import { Dashboard } from "../pages/Dashboard";
import { CouponsPage } from '../pages/coupons/index';
import { CouponCreate } from '../pages/coupons/create';
import { CouponView } from '../pages/coupons/view';

export default function BasePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }{" "}
        
        <ContentRoute exact path="/" component={BellazMain} />
        <ContentRoute path="/dashboard" component={Dashboard} />
        <ContentRoute path="/dashboard2" component={Demo2Dashboard} />
        <ContentRoute path="/builder" component={BuilderPage} />
        <ContentRoute path="/my-page" component={MyPage} />
        <Route path="/branches" component={BranchesPage} />
        <Route path="/branch/create" component={BranchCreate} />
        <Route path="/services" component={ServicesPage} />
        <Route path="/service/create" component={ServiceCreate} />
        <Route path="/packages" component={PackagesPage} />
        <Route path="/package/create" component={PackageCreate} />
        <Route exact path="/bookings/:status/:date?" component={BookingsPage} />
        <Route exact path="/booking/create" component={BookingCreate} />
        <Route exact path="/booking/present" component={TablePresent} />
        <Route exact path="/booking/tax" component={TableTax} />
        <Route path="/chairs" component={ChairsPage} />
        <Route path="/chair/create" component={ChairCreate} />
        <Route exact path="/staff" component={StaffPage} />
        <Route exact path="/staff/create" component={StaffCreate} />
        <Route exact path="/staff/leaves" component={StaffHolidayPage} />
        <Route exact path="/expense/category" component={ExpenseCategoryPage} />
        <Route exact path="/coupons" component={CouponsPage} />
        <Route exact path="/coupon/create" component={CouponCreate} />
        <Route exact path="/coupon/:couponUuid" component={CouponView} />
        <Route
          exact
          path="/expense/category/create"
          component={ExpenseCategoryCreate}
        />
        <Route
          exact
          path="/expense/category/:categoryUuid"
          component={ExpenseCategoryView}
        />
        <Route exact path="/expense/type" component={ExpenseTypePage} />
        <Route
          exact
          path="/expense/type/create"
          component={ExpenseCategoryTypeCreate}
        />
        <Route
          exact
          path="/expense/type/:typeUuid"
          component={ExpenseTypeView}
        />
        <Route
          exact
          path="/expense/cashDrawer"
          component={ExpenseCashDrawerPage}
        />
        <Route exact path="/expense/ra" component={ExpenseRaPage} />
        <Route exact path="/expense/po" component={ExpensePoPage} />
        <Route exact path="/expense/ra/create" component={ExpenseRaCreate} />
        <Route exact path="/expense/ra/:raUuid" component={ExpenseRaView} />
        <Route exact path="/expense/po/create" component={ExpensePoCreate} />
        <Route exact path="/expense/po/:poUuid" component={ExpensePoView} />
        <Route
          exact
          path="/loyaltyPoints"
          component={LoyaltyPointsBusinessPages}
        />
        <Route
          exact
          path="/staff/holiday/create"
          component={StaffHolidayCreate}
        />
        <Route exact path="/shifts" component={ShiftsPage} />
        <Route exact path="/holidays" component={HolidayPage} />
        <Route exact path="/holiday/create" component={HolidayCreate} />
        <Route exact path="/shift/create" component={ShiftCreate} />
        <Route exact path="/schedule" component={SchedulePage} />
        <Route exact path="/schedule/create" component={ScheduleCreate} />
        <Route exact path="/segments" component={SegmentsPage} />
        <Route exact path="/segment/create" component={SegmentCreate} />
        <Route exact path="/segment/:segmentUuid" component={SegmentView} />
        <Route
          exact
          path="/service/categories"
          component={ServiceCategoriesPage}
        />
        <Route
          exact
          path="/service/category/create"
          component={ServiceCategoryCreate}
        />
        <Route
          exact
          path="/service/category/:categoryUuid"
          component={ServiceCategoryView}
        />
        <Route exact path="/branch/:branchUuid" component={BranchView} />
        <Route exact path="/branch/:branchUuid/addImage" component={AddImage} />
        <Route exact path="/service/:serviceUuid" component={ServiceView} />
        <Route exact path="/package/:uuid" component={PackageView} />
        <Route exact path="/chair/:chairUuid" component={ChairView} />
        <Route exact path="/shift/:shiftUuid" component={ShiftView} />
        <Route exact path="/staff/:staffUuid" component={StaffView} />
        <Route exact path="/booking/:bookingUuid" component={BookingUpdate} />
        <Route
          exact
          path="/booking/view/:bookingUuid"
          component={BookingView}
        />
        <Route exact path="/subscriptions" component={SubscriptionsPage} />
        <Route
          exact
          path="/subscription/create"
          component={SubscriptionCreate}
        />
        {/* stripe routes */}
        <Route exact path="/checkout/:id/:du/:code?" component={CheckoutElement} />
        {/* <Route exact path="/subscription/:uuid" component={SubscriptionView} /> */}
        <Route exact path="/profile" component={ProfilePage} />
        <Route exact path="/transaction" component={TransactionPage} />
        <Route exact path="/payment/:date?" component={PaymentPage} />
        <Route exact path="/schedule/:uuid" component={ScheduleView} />
        <Route exact path="/customers" component={CustomerPage} />
        <Route exact path="/subscription/upgrade" component={Upgrade} />
        <Route exact path="/faqs" component={Faq} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/contact" component={Contact} />
        <Redirect to="error/error-v1" />
      </Switch>
    </Suspense>
  );
}
