import React from "react";
import "./BellazMain.css";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import StoreButton from "./component/Button";
function BellazDownload() {
  return (
    <div className="container-fluid w-100 d-flex flex-column p-0 download-banner-main-last justify-content-center">
      <div className="row p-0 m-0 see position-relative ">
        <img
          src={toAbsoluteUrl("/media/BellazMain/cardImages/person2.png")}
          alt=""
          className="w-50 img-fluid col-lg-5 col-md-5 p-0"
        />
        <img
          className="img-fluid position-absolute flower7 d-none d-lg-flex"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower-1.png")}
        />
        <img
          className="img-fluid position-absolute flower6 d-none d-lg-flex"
          src={toAbsoluteUrl("/media/BellazMain/svg icons/flower 2.png")}
        />
        <div className="col-lg-7 col-md-7 align-self-center downalod-upper pl-lg-10 pl-sm-2">
          <div className="pop">
            <h1 className="display-4 mb-lg-4 mb-sm-0 mb-md-0">
              India's no.1 application for beauty and salon
            </h1>
            {/* <p className="text-black-50 font-weight-light h6">
              Search from our wide variety of services and packages. Search from
              our wide valety of services.
            </p> */}
          </div>
          <div class="container p-0 m-0 w-100">
            <div class="row pop-row">
              <div class="col display-4 mb-4 font-weight-bold mb-2">
                500+<p className="h6 text-black-50">salons & spa partner</p>
              </div>
              <div class="col display-4 font-weight-bold mb-2">
                75,000+<p className="h6 text-black-50">appointments booked</p>
              </div>
              <div class="w-100 mt-5"></div>
              <div class="col display-4 font-weight-bold mb-4 mb-2">
                15,000+
                <p className="h6 text-black-50">stylist & professionals</p>
              </div>
              <div class="col display-4 font-weight-bold mb-2">
                120+ cities
                <p className="h6 text-black-50">with bellaz available</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card text-white download-bg">
        <img
          class="card-img h-100"
          src={toAbsoluteUrl("/media/BellazMain/cardImages/bgstrip.png")}
          alt="Card image"
        />
        <div className="card-img-overlay d-flex pr-5 align-items-sm-center align-items-center justify-content-end flex-lg-row flex-md-row m-0">
          <div className="text-white download-left-content p-0 ">
            <img
              src={toAbsoluteUrl("/media/logos/goldenLogo.png")}
              alt=""
              className="donwload-banner-logo img-fluid p-0 "
            />
            <h1 className="display-4">Download our app</h1>
            <h6 className="mb-lg-5">
              Book unforgettable beauty and wellness experiance with bellaz
              mobile app - the best way to discover top-rated nearby salons and
              spas
            </h6>
            <StoreButton />
          </div>
          <img
            src={toAbsoluteUrl("/media/BellazMain/cardImages/phone.png")}
            alt=""
            className="phone-img img-fluid m-0"
          />
        </div>
      </div>
    </div>
  );
}

export default BellazDownload;
