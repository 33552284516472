// import {all} from "redux-saga/effects";
import { combineReducers } from "redux";

import { customersSlice } from "../app/modules/ECommerce/_redux/customers/customersSlice";
import { productsSlice } from "../app/modules/ECommerce/_redux/products/productsSlice";
import { remarksSlice } from "../app/modules/ECommerce/_redux/remarks/remarksSlice";
import { specificationsSlice } from "../app/modules/ECommerce/_redux/specifications/specificationsSlice";
import otpReducer from "../store/otp/otpSlice";
import { authSlice } from "../store/business/authSlice";
import { authPersist } from "../store/business/authSlice";
import { segmentSlice } from "../store/admin/segment/segmentSlice";
import { categorySlice } from "../store/admin/category/categorySlice";
import { branchSlice } from "../store/business/branches/branchSlice";
import { dataSlice } from "../store/data/dataSlice";
import { serviceSlice } from "../store/business/services/servicesSlice";
import { chairSlice } from "../store/business/chairs/chairSlice";
import { shiftSlice } from "../store/business/shifts/shitftsSlice";
import { staffSlice } from "../store/business/staff/staffSlice";
import { holidaySlice } from "../store/business/staff/holiday/holidaySlice";
import { subscriptionSlice } from "../store/admin/subscription/subscriptionSlice";
import { packageSlice } from "../store/business/packages/packageSlice";
import { bookingSlice } from "../store/business/bookings/bookingSlice";
import { branchesHolidaySlice } from "../store/business/holiday/branchesHolidaySlice";
import { scheduleSlice } from "../store/business/schedule/scheduleSlice";
import { customerSlice } from "../store/admin/customer/customerSlice";
import { subscriptionPlanSlice } from "../store/business/subscriptionPlan/subscriptionPlanSlice";
import { addCustomerSlice } from "../store/business/addCustomer/customerSlice";
import { subscriptionCategoriesSlice } from "../store/admin/subscriptionList.js/subscriptionListSlice";
import { branchStaffSlice } from "../store/branch/branchStaffSlice";
import { branchShiftSlice } from "../store/branch/branch_shifts/branchShitftsSlice";
import { branchHolidaySlice } from "../store/branch/branch_holidays/branchHolidaySlice";
import { branchScheduleSlice } from "../store/branch/branch_schedule/branchScheduleSlice";
import { toastSlice } from "../store/toast/toastSlice";
// import { userManagementSlice } from "../store/admin/userManagement/userManagementSlice";
import { branchChairSlice } from "../store/branch/branch_chair/branchChairSlice";
import { branchPackageSlice } from "../store/branch/branch_package/branchPackageSlice";
import { branchServiceSlice } from "../store/branch/branch_service/branchServiceSlice";
import { invoiceSlice } from "../store/business/invoice/invoiceSlice";
import { branchInvoiceSlice } from "../store/branch/branchInvoiceSlice";
import { cashdrawerSlice } from "../store/business/expense/cashDrawer/cashdrawerSlice";
import { expenseCategorySlice } from '../store/business/expense/Category/categorySlice';
import { expenseTypeSlice } from '../store/business/expense/type/typeSlice';
import { expenseRaSlice } from '../store/branch/ra/raSlice';
import { expensePoSlice } from '../store/branch/po/poSlice';
import { expenseCategoryBranchSlice } from '../store/branch/category/categorySlice';
import { expenseCategoryTypeBranchSlice } from '../store/branch/type/typeSlice';
import { loyaltyPointsSlice } from '../store/business/loyaltyPoints/loyaltyPointsSlice';
import { countryCodeSlice } from '../store/countryCode/countryCodeSlice';
import { couponsSlice } from '../store/business/coupon/couponSlice';

export const rootReducer = combineReducers({
  customers: customersSlice.reducer,
  products: productsSlice.reducer,
  remarks: remarksSlice.reducer,
  specifications: specificationsSlice.reducer,
  otp: otpReducer,
  auth: authSlice.reducer,
  authPersist: authPersist,
  segments: segmentSlice.reducer,
  categories: categorySlice.reducer,
  branch: branchSlice.reducer,
  data: dataSlice.reducer,
  service: serviceSlice.reducer,
  chair: chairSlice.reducer,
  shift: shiftSlice.reducer,
  staff: staffSlice.reducer,
  holiday: holidaySlice.reducer,
  subscriptions: subscriptionSlice.reducer,
  package: packageSlice.reducer,
  schedule: scheduleSlice.reducer,
  booking: bookingSlice.reducer,
  branchesHoliday: branchesHolidaySlice.reducer,
  user: customerSlice.reducer,
  subscriptionPlan: subscriptionPlanSlice.reducer,
  addCustomer: addCustomerSlice.reducer,
  subscriptionCategories: subscriptionCategoriesSlice.reducer,
  branchStaff: branchStaffSlice.reducer,
  branchShifts: branchShiftSlice.reducer,
  branchHoliday: branchHolidaySlice.reducer,
  branchSchedule: branchScheduleSlice.reducer,
  invoice: invoiceSlice.reducer,
  toastSlice: toastSlice.reducer,
  invoice: invoiceSlice.reducer,
  // userData: userManagementSlice.reducer,
  branchChairs: branchChairSlice.reducer,
  branchPackages: branchPackageSlice.reducer,
  branchHoliday: branchHolidaySlice.reducer,
  branchServices: branchServiceSlice.reducer,
  branchInvoice:branchInvoiceSlice.reducer,
  cashDrawer: cashdrawerSlice.reducer,
  category: expenseCategorySlice.reducer,
  categoryType: expenseTypeSlice.reducer,
  ra: expenseRaSlice.reducer,
  po: expensePoSlice.reducer,
  brahchCategory : expenseCategoryBranchSlice.reducer,
  brahchCategoryType: expenseCategoryTypeBranchSlice.reducer,
  loyaltyPointsL: loyaltyPointsSlice.reducer,
  countryCode : countryCodeSlice.reducer,
  coupons: couponsSlice.reducer,
});

// export function* rootSaga() {
//   yield all([auth.saga()]);
// }
